import { debounce } from "./utilities.js";
export default () => {

	var activeClass = 'is-active';
	var scrolledClass = 'has-scrolled';
	var mobileSearchNav = $('.js-mobile-search-nav');
	var mobileSearchTrigger = $('[data-mobile-search-trigger]');

	if (!mobileSearchTrigger.length) {
		return;
	}
	$('.l-header__inner').addClass('hide-on-desktop-or-less');

	function attachEvents() {

		mobileSearchTrigger.on('click', function(e) {
			handleButtonClick($(this));
		});

		window.site.body.on('click', '.js-mobile-search-scroll-to-results', function() {
			mobileSearchTrigger.removeClass(activeClass);
			closeFilters();
			$('.js-search-results-page').removeClass(activeClass);

			setTimeout(function() {
				scrollToTopOfPage();
			}, 350); // After close animation
			return false;
		});

		window.site.win.on('scroll', debounce(function() {
			if (window.site.win.scrollTop() > 5) {
				mobileSearchNav.addClass(scrolledClass);
			} else {
				mobileSearchNav.removeClass(scrolledClass);
			}
		}));
	}

	function handleButtonClick(context) {

		// set relevent classes
		context.toggleClass(activeClass);
		mobileSearchTrigger.not(context).removeClass(activeClass);

		// toggle content
		var triggerType = context.attr('data-mobile-search-trigger').toLowerCase();
		if (triggerType === 'filter') {
			if (context.hasClass(activeClass)) {
				openFilters();
			} else {
				closeFilters();
			}
			$('.js-search-results-page').removeClass(activeClass);
		} else if (triggerType === 'search') {
			$('.js-search-results-page').toggleClass(activeClass);
			closeFilters();
		}

		if (context.hasClass(activeClass)) {
			scrollToTopOfPage();
		}
	}

	function openFilters() {
		$('.js-mobile-search-filters-container').addClass(activeClass);
		$('.js-mobile-search-filters').addClass('active');
		$('.js-mobile-search-filters-content').addClass('active');
	}

	function closeFilters() {
		$('.js-mobile-search-filters-container').removeClass(activeClass);
		$('.js-mobile-search-filters').removeClass('active');
		$('.js-mobile-search-filters-content').removeClass('active');
	}

	function scrollToTopOfPage() {
		$('html, body').animate({
			scrollTop: 0
		}, 500);
	}

	attachEvents();

}
